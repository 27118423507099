import React from 'react'
import { Button } from "./components/ui/button"
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "./components/ui/accordion"
import { FileText, Zap, Target, Award, CheckCircle } from 'lucide-react'

export default function LandingPage() {
  const handlePreSale = () => {
    // Implement pre-sale logic here (e.g., redirect to Lemonsqueezy or Paddle)
    console.log("Pre-sale button clicked")
  }

  return (
    <div className="min-h-screen bg-white">
      <header className="bg-white shadow-sm">
        <nav className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4">
          <div className="flex justify-between items-center">
            <div className="flex items-center">
              <FileText className="h-8 w-8 text-blue-600" />
              <span className="ml-2 text-xl font-bold text-gray-900">cvlization.com</span>
            </div>
            <div className="hidden md:flex space-x-4">
              {/* Commented out navigation links */}
              {/* <a href="#features" className="text-gray-600 hover:text-gray-900">Features</a> */}
              {/* <a href="#benefits" className="text-gray-600 hover:text-gray-900">Benefits</a> */}
              {/* <a href="#faq" className="text-gray-600 hover:text-gray-900">FAQ</a> */}
            </div>
            <Button onClick={handlePreSale} variant="primary">
              Pre-order Now
            </Button>
          </div>
        </nav>
      </header>

      <main>
        {/* Hero Section with Gradient */}
        <section className="relative overflow-hidden">
          <div className="absolute inset-0 bg-gradient-to-br from-blue-400 via-indigo-500 to-purple-600 opacity-75"></div>
          <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24 text-center">
            <h1 className="text-4xl sm:text-5xl font-extrabold text-white mb-6">
            Conquer the Job Market: Transform Your CV Strategy
            </h1>
            <p className="text-xl text-blue-100 mb-8">
            Join CVlization.com to effortlessly manage tailored CVs, streamline your job applications, and discover essential skills to outshine the competition. Empower your career journey today.
            </p>
            <Button onClick={handlePreSale} size="lg" variant="secondary" className="bg-white text-blue-600 hover:bg-gray-100">
              Pre-order Now - Limited Early Access
            </Button>
          </div>
        </section>

        {/* Pre-launch Announcement */}
        <section className="bg-gray-50 py-12">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
            <h2 className="text-2xl font-bold text-gray-900 mb-4">
              🚀 Coming soon: cvlization.com
            </h2>
            <p className="text-lg text-gray-600">
              We're gearing up for launch! Be one of the first to access our groundbreaking CV optimization platform.
            </p>
          </div>
        </section>

        {/* Features Section */}
        <section className="py-20" id="features">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <h2 className="text-3xl font-bold text-center text-gray-900 mb-12">
              Key features of cvlization.com
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              <FeatureCard
                icon={<Zap className="h-8 w-8 text-blue-600" />}
                title="AI-Powered Suggestions"
                description="Get intelligent recommendations to improve your CV content and structure."
              />
              <FeatureCard
                icon={<Target className="h-8 w-8 text-blue-600" />}
                title="ATS Optimization"
                description="Ensure your CV passes Applicant Tracking Systems with our smart analysis."
              />
              <FeatureCard
                icon={<Award className="h-8 w-8 text-blue-600" />}
                title="Industry-Specific Templates"
                description="Access professionally designed templates tailored to your industry."
              />
            </div>
          </div>
        </section>

        {/* Benefits Section */}
        <section className="bg-gray-50 py-20" id="benefits">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <h2 className="text-3xl font-bold text-center text-gray-900 mb-12">
              Why choose cvlization.com?
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              <BenefitItem text="Increase your chances of landing interviews" />
              <BenefitItem text="Save time with AI-powered CV creation" />
              <BenefitItem text="Stay ahead of the competition with cutting-edge technology" />
              <BenefitItem text="Receive personalized feedback and suggestions" />
            </div>
          </div>
        </section>

        {/* Pre-sale CTA Section */}
        <section className="bg-blue-600 py-20">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
            <h2 className="text-3xl font-bold text-white mb-6">
              Be among the first to join the CV revolution
            </h2>
            <p className="text-xl text-blue-100 mb-8">
              Pre-order cvlization.com now and get exclusive early access and special pre-launch pricing!
            </p>
            <Button onClick={handlePreSale} size="lg" variant="secondary" className="bg-white text-blue-600 hover:bg-gray-100">
              Secure Your Early Access
            </Button>
          </div>
        </section>

        {/* FAQ Section */}
        <section className="py-20" id="faq">
          <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8">
            <h2 className="text-3xl font-bold text-center text-gray-900 mb-12">
              Frequently Asked Questions
            </h2>
            <Accordion type="single" collapsible>
              <AccordionItem value="item-1">
                <AccordionTrigger value="item-1">How does cvlization.com work?</AccordionTrigger>
                <AccordionContent value="item-1">
                  cvlization.com uses advanced AI algorithms to analyze your CV, provide suggestions for improvement, and optimize it for Applicant Tracking Systems. It also offers industry-specific templates and personalized recommendations.
                </AccordionContent>
              </AccordionItem>
              <AccordionItem value="item-2">
                <AccordionTrigger value="item-2">Is my data secure on cvlization.com?</AccordionTrigger>
                <AccordionContent value="item-2">
                  Yes, we take data security very seriously. All your information is encrypted and stored securely. We never share your personal data with third parties.
                </AccordionContent>
              </AccordionItem>
              <AccordionItem value="item-3">
                <AccordionTrigger value="item-3">What do I get with the pre-order?</AccordionTrigger>
                <AccordionContent value="item-3">
                  By pre-ordering, you'll get early access to cvlization.com before its public release, exclusive discounted pricing, and priority support from our team.
                </AccordionContent>
              </AccordionItem>
              <AccordionItem value="item-4">
                <AccordionTrigger value="item-4">When will cvlization.com be launched?</AccordionTrigger>
                <AccordionContent value="item-4">
                  We're working hard to launch cvlization.com soon. Pre-order customers will be the first to know about our official launch date and will get priority access to the platform.
                </AccordionContent>
              </AccordionItem>
            </Accordion>
          </div>
        </section>
      </main>

      <footer className="bg-gray-800 text-white py-8">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-center space-x-6">
            <a href="#" className="text-gray-300 hover:text-white">Privacy Policy</a>
            <a href="#" className="text-gray-300 hover:text-white">Terms of Service</a>
            {/* Commented out additional footer links */}
            {/* <a href="#" className="text-gray-300 hover:text-white">Contact</a> */}
            {/* <a href="#" className="text-gray-300 hover:text-white">About Us</a> */}
          </div>
          <div className="mt-4 text-center">
            <p className="text-gray-400">&copy; 2024 cvlization.com. All rights reserved.</p>
          </div>
        </div>
      </footer>
    </div>
  )
}

function FeatureCard({ icon, title, description }) {
  return (
    <div className="bg-white rounded-lg shadow-md p-6">
      <div className="flex items-center justify-center mb-4">
        {icon}
      </div>
      <h3 className="text-xl font-semibold text-gray-900 mb-2">{title}</h3>
      <p className="text-gray-600">{description}</p>
    </div>
  )
}

function BenefitItem({ text }) {
  return (
    <div className="flex items-start">
      <CheckCircle className="h-6 w-6 text-green-500 mr-2 flex-shrink-0" />
      <p className="text-lg text-gray-700">{text}</p>
    </div>
  )
}