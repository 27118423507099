import React, { useState, createContext, useContext } from 'react'

const AccordionContext = createContext(null)

export function Accordion({ children, type = "single", ...props }) {
  const [openItems, setOpenItems] = useState(new Set())

  return (
    <AccordionContext.Provider value={{ openItems, setOpenItems, type }}>
      <div className="divide-y divide-gray-200" {...props}>{children}</div>
    </AccordionContext.Provider>
  )
}

export function AccordionItem({ children, value, ...props }) {
  return (
    <div className="py-4" {...props}>
      <AccordionContext.Consumer>
        {({ openItems }) => (
          <div data-state={openItems.has(value) ? "open" : "closed"}>
            {children}
          </div>
        )}
      </AccordionContext.Consumer>
    </div>
  )
}

export function AccordionTrigger({ children, value, ...props }) {
  const { openItems, setOpenItems, type } = useContext(AccordionContext)

  const handleClick = () => {
    setOpenItems(prev => {
      const newOpenItems = new Set(prev)
      if (newOpenItems.has(value)) {
        newOpenItems.delete(value)
      } else {
        if (type === "single") {
          newOpenItems.clear()
        }
        newOpenItems.add(value)
      }
      return newOpenItems
    })
  }

  const isOpen = openItems.has(value)

  return (
    <button 
      className="flex justify-between w-full text-left font-medium text-gray-900"
      onClick={handleClick}
      {...props}
    >
      {children}
      <span className="ml-6 flex items-center">
        {isOpen ? (
          <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 15l7-7 7 7" />
          </svg>
        ) : (
          <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
          </svg>
        )}
      </span>
    </button>
  )
}

export function AccordionContent({ children, value, ...props }) {
  const { openItems } = useContext(AccordionContext)
  const isOpen = openItems.has(value)

  if (!isOpen) return null

  return <div className="mt-2 pr-12" {...props}>{children}</div>
}