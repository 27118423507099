import React from 'react';
import LandingPage from './LandingPage';
import './App.css';
import { FileText, Zap, Target, Award, CheckCircle } from 'lucide-react';

function App() {
  return (
    <div className="App">
      <div className="min-h-screen bg-white">
        <LandingPage />
        <FileText className="h-8 w-8 text-blue-600" />
      </div>
    </div>
  );
}

export default App;