import React from 'react'

export function Button({ children, className = '', variant = 'primary', ...props }) {
  const baseStyles = 'px-4 py-2 rounded font-semibold transition duration-300'
  const variantStyles = {
    primary: 'bg-blue-600 text-white hover:bg-blue-700',
    secondary: 'bg-white text-blue-600 hover:bg-gray-100'
  }

  return (
    <button 
      className={`${baseStyles} ${variantStyles[variant]} ${className}`}
      {...props}
    >
      {children}
    </button>
  )
}